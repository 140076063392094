import axios from "axios";
import { request } from "@/utils/request";
// 查询数据
export function selectBanner(data) {
  return request({
    url: `/admin/banner/select`,
    method: "POST",
    data,
  });
}

// 新增数据
export function addBanner(data) {
  return request({
    url: `/admin/banner/add`,
    method: "POST",
    data,
  });
}

/* 修改数据 */
export function updateBanner(data) {
  return request({
    url: `/admin/banner/update`,
    method: "POST",
    data,
  });
}

// 删除数据
export function deleteBanner(data) {
  return request({
    url: `/admin/banner/delete`,
    method: "POST",
    data,
  });
}

/* 设置隐藏 */
export function updateRecommend(data) {
  return request({
    url: `/admin/banner/updateRecommend`,
    method: "POST",
    data,
  });
}
