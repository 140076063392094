export default function() {
  /* 表格列头 */
  return [
    // { label: "序号", type: "index", width: 80 },
    // { label: "轮播图ID", prop: "accountID", width: 150 },
    // { label: "轮播图类型", prop: "type", slots: "type" },
    { label: "名称", prop: "name", width: 300 },
    // { label: "电话", prop: "phone", width: 140 },
    { label: "图片", prop: "img", slots: "img", width: 500 },
    { label: "是否隐藏", prop: "recommend", slots: "recommend", width: 300 },
    // {
    //   label: "更新时间",
    //   prop: "createTime",
    //   width: 180,
    //   // slots: "state",
    // },
    {
      label: "操作",
      prop: "operation",
      width: 300,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
